.svg-content { 
	display: inline-block;
	position: absolute;
	top: 0;
	left: 0;
}

.svg-container { 
	display: inline-block;
	position: relative;
	width: 100%;
	padding-bottom: 70%; 
	vertical-align: middle; 
	overflow: hidden; 
}

.exp-LektonFonts {
  font-family: sans-serif;
}

.exp-LargeFonts.fonts-loaded {
  font-family: Anton, sans-serif;
}

.exp-LektonFonts.fonts-loaded {
  font-family: Lekton, sans-serif;
}

.dot {
    -webkit-box-align: center;
    -webkit-align-items: center;
    align-items: center;
    background-color: #fff;
    border-radius: 50%;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    height: 7px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    margin: 0 8px;
    width: 7px;
  }

  .pulse {
    border: 3px solid #fff;
    border-radius: 50%;
    -webkit-box-flex: 0;
    -webkit-flex: none;
    flex: none;
    height: 15px;
    margin: 0 -15px;
    width: 15px;
    -webkit-animation: busyness-live-pulsate 1.8s ease-out;
    animation: busyness-live-pulsate 1.8s ease-out;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
  }

  @keyframes busyness-live-pulsate {
    0% {
        -webkit-transform: scale(.1,.1);
        transform: scale(.1,.1);
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        -webkit-transform: scale(1.2,1.2);
        transform: scale(1.2,1.2);
        opacity: 0;
    }
  }

.diagonalCross {
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom right, #FFFFFF00 calc(50% - 1px), #99999933 , #FFFFFF00 calc(50% + 1px) )
}