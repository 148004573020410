@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  background-color: #CBD5E1;
  color: #32313b;
  font-family: "Open Sans", sans-serif;
  font-display: block;
  display: flex;
  width: 100vw;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
}

body {
  /*
  background-image: url("./Assets/Backgrounds/graphy-dark.png");
  background-image: url("./Assets/Backgrounds/graphy.png");
  background-image: url("./Assets/Backgrounds/inflicted.png");
  background-image: url("./Assets/Backgrounds/cartographer.png");
  background-image: url("./Assets/Backgrounds/cardboard-flat.png");
  background-image: url("./Assets/Backgrounds/axiom-pattern.png");
  */
}

#name-industry-application {
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  overflow: hidden;
}

.console-container {
  position: fixed;
  bottom: 0px;
  -webkit-perspective: 0;
  -webkit-backface-visibility: hidden;
  -webkit-transform: translate3d(0,0,0);
  visibility:visible;
  backface-visibility: hidden;
  will-change: transform;
}

.header-glass {
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(5px);
}

.btn-blue-square {
  @apply px-4 py-2 shadow-sm transition ease-in-out duration-200 leading-snug whitespace-nowrap text-base font-semibold;
  background-color: #03a9f4;
  color: white;
}

.btn-blue-square:hover {
  @apply px-4 py-2 shadow-sm transition ease-in-out duration-200 leading-snug whitespace-nowrap text-base font-semibold;
  background-color: #2196f3;
  color: white;
}

.btn-blue-square-disabled {
  @apply px-4 py-2 shadow-sm transition ease-in-out duration-200 leading-snug whitespace-nowrap text-base font-semibold;
  background-color: #58c2f3;
  color: #c0ecff;
}

.font-open-sans {
  font-family: "Open Sans", sans-serif;
}

.font-lekton {
  font-family: "Lekton", sans-serif;
}

.font-anton {
  font-family: "Anton", sans-serif;
}

.font-montserrat {
  font-family: "Montserrat";
}